import "./profilTab.scss";

import { cardSettingsSwimlane } from "~swimlaneViews/cardSettingsSwimlane";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";
import { Storage } from "~ui-lib";

import { OptionsProfilSwimlane } from "../../swimlaneViews/optionsProfilSwimlane";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { magnetoVersion } from "../player/playerMagnetoPage";

export enum profilType {
  options = "options",
  aPropos = "à propos",
}
export class ProfilTab extends View {
  constructor() {
    super(DOMHelper.createDivWithParent(null, "ProfilTab"));
    DOMHelper.createDivWithParent(this.rootElement, null, "profilPage", "Profil");
    this.delegate = createListComponent(
      {
        rootElement: this.rootElement,
        modelSource: new StaticModelSource([profilType.options, profilType.aPropos]),
        viewFactory: model => {
          if (model == profilType.options) return new OptionsProfilSwimlane();
          else return new cardSettingsSwimlane();
        },
        horizontal: false,
        pageSize: 2,
      },
      mainList => {
        mainList.setFocusOnIndex(0);
      }
    );
    DOMHelper.createDivWithParent(
      this.rootElement,
      "VersionNumber",
      null,
      __APP_VERSION__ + (magnetoVersion() !== "" ? "-" + magnetoVersion() : "")
    );
  }

  onShown() {
    const storageMuted = Storage.getItem("muted");
    const toggleState = storageMuted === null || storageMuted === "false" ? "effets_sonores_on" : "effets_sonores_off";
    sendPianoAnalytic("page.display", { page: "profil", page_type: "compte" }, { toggle_state: toggleState });
  }
}
